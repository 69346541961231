<template>
  <nav class="navbar navbar-expand-lg bg-light shadow-sm navbar fixed-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="#">
        <img src="@/assets/logo.png" alt="logo" class="img-logo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

          <!-- <li class="nav-item px-1">
            <router-link class="nav-link fs" to="#">Home</router-link>
          </li>

          <li class="nav-item px-1">
            <a class="nav-link fs" href="/" target="_blank">Main Site</a>
          </li>


          <li class="nav-item px-1 ">
            <a class="nav-link fs" href="/" target="_blank">Contact Us</a>
          </li>

          <li class="nav-item px-1">
            <router-link class="nav-link fs" to="/">Search</router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>




<style scoped>
.img-logo {
  /* width: 50px;
  height: 50px; */
  width: 50px;
}

/* .router-link-active {
  font-weight: bold;
} */

.fs {
  font-family: PT Sans, sans-serif;
  font-size: 17px;
  color: black;

}



/* .nav-item:hover {
  background-color: rgb(238, 238, 238);
  opacity: 0.8;

} */

/* .container-fluid {
  height: 5px;
} */
</style>
