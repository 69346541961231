import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },

  {
    path: "/saloon/:salonUrl",
    name: "Saloon",
    component: () => import("../views/ViewProfile.vue"),
  },

  {
    path: "/category/:salonUrl",
    name: "SaloonCategory",
    component: () => import("../views/ViewCategory.vue"),
  },

  {
    path: "/saloon/:salonUrl/:categoryUrl",
    name: "SaloonCatlog",
    component: () => import("../views/ViewCatlog.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | Glam Connect";
  NProgress.configure({ showSpinner: false });
  NProgress.start();

  next();
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
