import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import $ from "jquery";

import "nprogress/nprogress.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use($)
  .use(LoadingPlugin, { loader: "dots", color: "#355b7c" })

  .mount("#app");
